import React from "react";
import nuovaImmagine from "../assets/RUSSO4.jpg";
import "./Herothree.css";

function Herothree() {
  return (
    <div className="content">
      <img src={nuovaImmagine} alt=""></img>
    </div>
  );
}

export default Herothree;
