import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import image1 from "../assets/Dettaglio1.jpg";
import image2 from "../assets/Dettaglio2.jpg";
import image3 from "../assets/Dettaglio3.jpg";
import image4 from "../assets/Dettaglio4.jpg";
import image5 from "../assets/Dettaglio5.jpg";
import image6 from "../assets/Dettaglio6.jpg";
import image7 from "../assets/Dettaglio7.jpg";
import image8 from "../assets/Dettaglio8.jpg";
import image9 from "../assets/Dettaglio9.jpg";
import image10 from "../assets/Dettaglio10.jpg";
import image11 from "../assets/Dettaglio11.jpg";
import image12 from "../assets/Dettaglio12.jpg";
import image13 from "../assets/Dettaglio13.jpg";
import image14 from "../assets/Dettaglio14.jpg";
import image15 from "../assets/Dettaglio15.jpg";
import image16 from "../assets/Dettaglio16.jpg";

// import required modules
import { Pagination } from "swiper/modules";
import "swiper/css/navigation";

import "./SwiperCarousel.css";

function SwiperCarouselMix() {
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },

          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            className="foto"
            src={image1}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image2}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image3}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image4}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image5}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image6}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image7}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image8}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image9}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image10}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image11}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image12}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image13}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image14}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image15}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="foto"
            src={image16}
            alt="russo-abbigliamento-collezione"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SwiperCarouselMix;
