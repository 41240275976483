import React from "react";
import nuovaImmagine from "../assets/RUSSO3.jpg";

import "./Herotwo.css";

function Herotwo() {
  return (
    <div className="content">
      <img src={nuovaImmagine} alt=""></img>
    </div>
  );
}

export default Herotwo;
