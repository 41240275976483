import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  const instaUrl = "https://www.instagram.com/russoabbigliamento/";

  return (
    <div className="footer">
      <div className="social-icons">
        <a href="https://www.facebook.com/russo.abbigliamento1/">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href={instaUrl}>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
      <p className="partita-iva">Russo Abbigliamento P.IVA: 04861580654</p>
      <Link to="/NoteLegali" className="note-legali">
        Note Legali
      </Link>
      <p className="copyright">
        made with &hearts; by{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={encodeURI("https://webagencywave.com/")}
        >
          {" "}
          wave
        </a>
      </p>
    </div>
  );
}

export default Footer;
