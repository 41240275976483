import React from "react";
import nuovaImmagine from "../assets/RUSSO2.jpg";
import "./Heroone.css";

function Heroone() {
  return (
    <div className="content">
      <img src={nuovaImmagine} alt=""></img>
    </div>
  );
}

export default Heroone;
